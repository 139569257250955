import * as React from "react";
import { graphql } from "gatsby";
import EDataAutomationView from "../../views/EDataAutomation/EDataAutomationView";

const TbizShopsPage = () => {
  return <EDataAutomationView />;
};

export default TbizShopsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
