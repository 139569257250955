import React, { useEffect, useState } from "react";
import { styled } from "../../../stitches.config";
import { Colors, Hero } from "../../components/shared/Hero";
import { Layout } from "../../layout";
import EDataAutomation from "../../images/deco/eDataAutomation.png";
import { Button } from "../../components/shared/Button";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { HomeSliderSection } from "../../components/Home/HomeSlider";
import { LiveSupport } from "../../components/shared/LiveSupport";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { routes } from "../../routes/routes";
import { sendRequestToAPI } from "../../api/graphqlRequest";
import { SuccessStory } from "../../types";
import { Seo } from "../../components/shared/Seo";

function EDataAutomationView() {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  // const [successStories, setSuccessStories] = useState<SuccessStory[]>([]);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const { data } = await sendRequestToAPI(
  //         `
  //         query{successStories{
  //             data{
  //              attributes{
  //                shortDescription
  //                representativeName
  //                longDescription
  //                companyName,
  //                companyLogo{data{attributes{url}}}
  //                representativeAvatar{data{attributes{url}}}
  //              }
  //            }}
  //         }
  //         `
  //       );
  //       setSuccessStories(data.data.successStories.data);
  //     } catch (err) {
  //       return err;
  //     }
  //   };
  //   getData();
  // }, []);

  const bgColor = Colors.transparent;
  return (
    <Layout>
      <Seo
        title={t("eDataAutomationHeading")}
        description={t("eDataAutomationSubHeading1")}
        image={EDataAutomation}
      />
      <Hero
        pic={{ wrap: Image, src: EDataAutomation }}
        backgroundColor={bgColor}
      >
        <BreadCrumbs
          root={{
            link: routes.tbizedi.edi,
            title: "tbizedi",
          }}
          current={"tbizediSubs.automation"}
        />
        <Heading color={bgColor}>{t("eDataAutomationHeading")}</Heading>
        <HeroDesc color={bgColor}>{t("eDataAutomationSubHeading1")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("eDataAutomationSubHeading2")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("eDataAutomationSubHeading3")}</HeroDesc>
        <ButtonsWrap>
          <Button onClick={() => navigate(routes.contact.contactForm)}>
            {t("eDataAutomationContactUs")}
          </Button>
        </ButtonsWrap>
      </Hero>
      {/* <SuccessStories>
        <ContentWrapp>
          <StoriesTextWrapp>
            <StoriesHeading>{t("eDataAutomationStiriesTitle")}</StoriesHeading>
            <StoriesDescription>
              {t("eDataAutomationStiriesDesc")}
            </StoriesDescription>
          </StoriesTextWrapp>
        </ContentWrapp>
        {!!successStories.length && (
          <HomeSliderSection successStories={successStories} />
        )}
      </SuccessStories> */}
    </Layout>
  );
}

export default EDataAutomationView;

const Image = styled("img", {
  maxWidth: "504px",
  objectFit: "contain",
  width: "90%",
});

const Heading = styled("h1", {
  color: "$white",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "30px 0 0",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },

  "@md": {
    margin: "0",
    fontSize: "48px",
    lineHeight: "56px",
    color: "$heading",
    maxWidth: "620px",
  },
});

const HeroDesc = styled("p", {
  color: "$white",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "12px 0 0 ",
  "&:last-of-type": {
    margin: "12px 0px 32px",
  },

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },

  "@md": {
    color: "$text",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginBottom: 80,
  "@md": {
    marginBottom: 80,
    flexDirection: "row",
  },
});

const ContentWrapp = styled("div", {
  display: "block",
  maxWidth: "1280px",
  margin: "0 auto",
});

const SuccessStories = styled("div", {
  display: "block",
  width: "100%",
});

const StoriesHeading = styled("h2", {
  color: "$heading",
  fontWeight: 700,
  fontSize: "20px",
  lineHeight: "24px",
  textAlign: "center",
  margin: "0",
  marginBottom: "25px",

  "@md": {
    fontSize: "24px",
    lineHeight: "28px",
    marginBottom: "32px",
  },
});

const StoriesDescription = styled("p", {
  textAlign: "center",
  color: "$text",
  fontSize: "15px",
  lineHeight: "20px",
  fontWeight: 400,
  "@md": {
    fontSize: "17px",
    lineHeight: "24px",
    maxWidth: "900px",
  },
});

const StoriesTextWrapp = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "$secondaryBackground",
  padding: "60px 16px",

  "@sm": {
    paddingBottom: "20px",
    width: "100%",
    backgroundColor: "$white",
  },
});
